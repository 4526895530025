.aheto-footer-7 {
  &__main {
    padding: 110px 0 0;
    @include medium-size-max {
      padding: 50px 0 0;
    }
  }
  .aht-socials {
    &__link {
      margin-right: 15px;
      margin-left: 15px;
    }
    &__icon {
      color: $c-white;
      font-size: 20px;
    }
  }
  &__bottom {
    padding: 35px 0 80px;
    text-align: center;
  }
}
