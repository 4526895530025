.main-header__toolbar-wrap {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $c-dark;
  }

  .widget,
  .registartion,
  .log-in {
    a {
      color: $c-dark;

      &:hover {
        color:$c-active;
      }
    }
  }

  &--solid {
    background-color: $c-light;
  }

  &--light {
    background-color: $c-dark;
  }
}

.aheto-header-1 {
  &__toolbar {
    border-bottom: 1px solid $c-alter-3;
  }

  &__detail-icon {
    color: $c-alter;
  }

  &__detail-text,
  &__detail-link {
    color: $c-dark;
  }

  &__nav-wrap {
    background: linear-gradient($c-alter, $c-alter) no-repeat 950px 0;

    @include large-size-max {
      background-color: $c-alter;
      box-shadow: 0px 0px 30px 0px rgba($c-alter, 0.3);
    }
  }

  &__nav {
    background-color: $c-alter;
    box-shadow: 0px 0px 30px 0px rgba($c-alter, 0.3);
  }

  .sub-menu {

    .dropdown-btn {
      color: $c-dark;
    }

    .menu-item {
      a {
        color: $c-dark;
      }

      &:hover {
        > .dropdown-btn,
        > a {
          color: $c-active;
        }
      }
    }
  }

  .mega-menu {
    &__title {
      border-bottom: 1px solid $c-alter-3;
      color: $c-alter;
    }

    @include menu-size-min {
      &__col {
        border-right: 1px solid $c-alter-3;
      }
    }
  }

  // Socials
  .aheto-socials {
    &__icon {
      color: lighten($c-grey, 15%);

      &:hover {
        color: $c-grey;
      }
    }
  }
}


.aheto-header-2 {
  &__line {
    @include menu-size-max {
      background-color: $c-dark;
    }
  }

  &__menu {

    @include menu-size-max {
      border-bottom: 1px solid rgba($c-dark, 0.1);
    }
  }

  .main-menu {
    @include menu-size-min {

      >.menu-item {

        >a {
          &:hover {
            color: $c-active;
          }
        }

        >a.active {
          color: $c-active;
        }


      }
    }
  }

  // Socials
  .aht-socials {
    &__link {
      color: $c-white !important;
      &:hover {
        color: $c-active !important;
      }
    }
  }

  .sub-menu {

    .menu-item {
      a {
        color: $c-dark;
      }

      a.active {
        color: $c-active;
      }

      &:hover {
        > a,
        .dropdown-btn {
          color: $c-active;
        }
      }
    }

    @include menu-size-min {
      .dropdown-btn {
        color: $c-dark;
      }
    }
  }

  // Mega-menu
  .mega-menu {

    @include menu-size-min {
      &__title {
        border-bottom: 1px solid rgba($c-dark, 0.1);
      }

      &__col {
        padding: 30px 20px;
        border-right: 1px solid rgba($c-dark, 0.1);
      }
    }
  }

  // Logo
  .logo {

    &__img-holder {
      background-color: $c-dark;
    }
    &__text {
      color: $c-dark;
    }
  }
}

.aheto-header-3 {
  &__menu {
    @include menu-size-max {
      background-color: $c-dark;
    }
  }

  &__inner {
    @include large-size-max {
      background: $c-dark;
    }
  }
  .main-menu {
    .menu-item {
      a {
        color: $c-white;
      }
      
    }
    
  }

  // Search
  &__search-icon {
    &:hover {
      color:$c-active;
      transition: 0.3s all;
    }
  }
  .sub-menu {
    .menu-item {
      a {
        &:hover {
          color:$c-active;
        }
      }
      &:hover {
        > a {
          color:$c-active;
          + span {
            color:$c-active;
          }
        }
      }
    }
  }

  // Menu
  .main-menu {
    > .menu-item {
       a {
        + span {
          transition: all 0.05s;
        }
        &:hover {
          color: $c-active;
          + span {
            color: $c-active;
            transition: all 0.02s;
          }
        }
      }
      &:hover {
        > a {
          color: $c-active;
          + span {
            color: $c-active;
            transition: all 0.02s;
          }
        }
      }
      &:after {
        background-color: $c-active;
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      box-shadow: 1.169px 3.825px 15.66px 2.34px rgba($c-active, 0.2);
    }
  }

  .aheto-btn {
    
    // btn light
    &--light {
      &:hover {
  	     border:1px solid rgba($btn-light-border, $btn-border-opacity);
  	      background-color: $btn-light-bg;
  	     color: $c-active!important;
      }
    }
 }
 @media screen and (min-width: 320px) {
   .main-menu {
    .menu-item {
      &-active {
        width: fit-content;
        &::after {
          display: inline-block!important;
          position: absolute;
          bottom: 5px;
          left: 0;
          width: 100%;
          height: 1px;
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
          background-color: $c-active;
          content: '';
        }
      }
    }
   }
  
}
}

.aheto-header-4 {
  &__logo {
    background-color: $c-light;
  }

  &__button-icon {
    color: $c-dark;
    transition: 0.3s all;
    &:hover {
      color:$c-active;
      transition: 0.3s all;
    }
  }

  // Main menu
  .main-menu {

    @include menu-size-min {
      > .menu-item {
        > a {
          color: $c-dark;
        }
      }
    }

    @include menu-size-max {

      .menu-item {
        a {
          color: $c-dark;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      .menu-item {
        a {
          color: $c-dark;
        }

        &:hover {
          > a {
            color: $c-active;
          }

          > .dropdown-btn {
            color: $c-active;
          }
        }
      }
    }
  }

  // Menu item
  .mega-menu {
    @include menu-size-min {
      &__title {
        color: $c-grey;
      }
    }

    @include menu-size-max {
      &__title {
        color: $c-active;
      }
    }
  }

  // Logo
  .logo {
    &__text {
      color: $c-active;
    }

    &__sub-text {
      color: $c-active;
    }
  }

  .hamburger {

    &-inner {
      background-color: $c-dark;

      &::before,
      &::after {
        background-color: $c-dark;
      }
    }
  }
}


.aheto-header-5 {
  h3 {
    line-height: 1.7;
  }

  .main-menu .menu-item a {
    font-family: $f-futura !important;
    font-size: 16px;
    letter-spacing: 1px;
  }

  .menu-depth-1 a {
    font-size: 14px !important;
  }

  &__button-icon {
    color: $c-dark;
    transition: 0.3s all;
    &:hover {
      color:$c-active;
      transition: 0.3s all;
    }
  }

  &__button-number {
    background-color: $c-active;
  }

  // Menu
  .main-menu {

    .menu-item {
      a {
        color: $c-dark;
        font-family: $fnt-family-1;
      }
    }

    @include menu-size-min {
      > .menu-item {
        &:before {
          // background-color: $c-active;
        }
      }

      .menu-item {
        &:hover {
          > a,
          > .dropdown-btn {
            color: $c-active;
          }
        }
      }
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min {
      border-top: 2px solid $c-active;
    }
  }

  // Mega menu
  .mega-menu {

    @include menu-size-min {
      &__col {
        border-top: 2px solid $c-active;
      }
    }

    @include menu-size-max {
      &__title {
        color: $c-active;
      }
    }
  }

  // Dropdown btn
  .dropdown-btn {
    color: $c-dark;
  }

  // Logo
  .logo {
    &__text {
      font-family: $fnt-family-1;
    }
  }

  // Hamburger
  .hamburger {
    &-inner {
      background-color: $c-dark;

      &::before,
      &::after {
        background-color: $c-dark;
      }
    }
  }
}

.aheto-header-6 {
  &__toolbar {
    background-color: $c-dark;

    a,
    button {
      &:hover {
        color: $c-active;
      }
    }
  }
  .wpml-ls-native {
    font-size: 14px;
    font-family: $f-roboto;
  }
  &__toolbar {
    &-text, &-tel, &-email, &-authentication {
      font-family: $f-roboto;
    }
  }
  
  &__search {
    input {
      color: $c-dark;

      &[type=text] {
        background-color: $c-light;
        &::placeholder {
          color: $c-grey;
        }
      }
    }

    button[type="submit"] {
      color: $c-dark;

      &:hover {
        color: $c-active;
      }
    }
  }

  // Menu
  .main-menu {
    .menu-item {
      a {
        font-family: $fnt-family-2;
      }
    }
    .current-menu-item {
      a {
        &::before {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .current-menu-item {
        position: relative;
        width: fit-content;
        &::after {
          display: inline-block !important;
          position: absolute;
          bottom: 5px;
          left: 0;
          width: 100%;
          height: 2px;
          -webkit-transition: all 0.2s;
          background-color: $c-active;
          transition: all 0.2s;
          content: '';
        }
      }
    }

    @include menu-size-min {
      display: flex;

      > .menu-item {
        > a {
          &:before {
            background-color: $c-active;
          }
        }
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      background-color: $c-dark;

      .menu-item {
        &:hover {
          > a,
          > .dropdown-btn {
            color: $c-active;
          }
        }
      }
    }
  }

  // Dropdown-btn
  .dropdown-btn {
    color: $c-dark;
  }

  // Logo
  .logo {
    &__sub-text {
      color: $c-grey;
      font-family: $fnt-family-1;
    }
  }

  // Authentication
  .authentication {
    &__log-in,
    &__register {
      font-family: $f-roboto;
    }
  }

  // Socials
  .aht-socials.aheto-socials--circle {
    .aht-socials {
      display: flex;

      &__link {
        color: $c-dark;

        &:hover {
          border-color: $c-active;
          background-color: $c-active;
          color: $c-dark;
        }
      }
    }
  }

  // Hamburger
  .hamburger {
    &-inner {
      background-color: $c-dark;

      &:before,
      &:after {
        background-color: $c-dark;
      }
    }
  }
}

.aheto-header-7 {
  &__inner {
    background-color: $c-dark-2;
    @include menu-size-max {
      background: linear-gradient(to right, $c-dark-2, lighten($c-dark-2, 7%));
    }
  }

  &__aside-close {
    color: $c-dark;
  }

  &__contact {
    .logo {
      &__text {
        color: $c-dark-2;
      }
    }
  }

  &__email,
  &__tel {
    color: $c-dark;

    &:hover {
      color: $c-active;
    }
  }

  &__menu {
    @include menu-size-max {
      background: linear-gradient(to right, $c-dark-2, lighten($c-dark-2, 7%));
    }
  }

  .main-menu {
    @include menu-size-min {
      .menu-item {
        a {
          color: $c-grey;
        }
      }
    }
  }

  .sub-menu {
    @include menu-size-min {
      background-color: $c-dark-2;
    }
  }

  .dropdown-btn {
    color: $c-grey;
  }

  .aheto-copyright {
    p {
      color: $c-grey;
    }
  }
}

.aheto-header-8 {

  &__inner {
    @include menu-size-max {
      background: linear-gradient(to right, darken($c-active, 10%), $c-active );
    }
  }

  // Menu
  &__menu {

    @include menu-size-max {
      background: linear-gradient(to right, darken($c-active, 10%), $c-active );
    }
  }
  // Sub menu
  .sub-menu {

    @include menu-size-min {
      .menu-item {
        a {
          color: $c-grey;
        }

        &:hover {
          > a,
          > .dropdown-btn {
            color: $c-active;
          }
        }
      }

      .dropdown-btn {
        color: $c-grey;
      }
    }
  }

  // Button
  .aheto-btn {
    color: $c-active;

    &:hover {
      background-color: $c-alter-2;
    }
  }

  &__search-icon {
    color: $c-white;
    font-size: 24px;

    &:hover {
      color:$c-alter-2;
    }
  }
}

.aheto-header-9 {
  &__line {
    @include menu-size-max {
      background-color: $c-active;
      background-image: linear-gradient(to right , #69b9f2, #559be5, #417fda, #3065c8, #2052c9);
      padding: 10px 0px;
    }
  }

  &__menu {

    @include menu-size-max {
      background-color: $c-active;
      background-image: linear-gradient(to right , #69b9f2, #559be5, #417fda, #3065c8, #2052c9);
    }
  }

  .menu-item {
    .sub-menu{
      a.active{
        color: $c-alter;
      }
    }
    > a {
     + span {
       transition: all 0.02s;
     }
     &:hover {
       color:fade-out($c-white, 0.25);
       + span {
         color:fade-out($c-white, 0.25);
         transition: all 0.02s;
       }
     }

   }
   &:hover {
     > a {
       color:fade-out($c-white, 0.25);
       + span {
         color:fade-out($c-white, 0.25);
         transition: all 0.02s;
       }
     }
   }
  }
  // Sub menu
  .sub-menu {
    .menu-item {
      &:hover {
        > a {
          color:$c-alter;
          + span {
            color:$c-alter;
          }
        }
      }
      a {
        &:hover {
          color:$c-alter;
          + span {
            color:$c-alter;
          }
        }
      }
    }
  }

  // Authentication
  .authentication {
    &__sign-in {
        &:hover {
          color:$c-alter;
        }
    }
    &__sign-up {
      color: $c-active;

      &:hover {
        background-color: $c-alter;
        color: $c-white;
      }
    }
  }
}

.aheto-header-10 {
  &__toolbar {
    background-color: $c-dark;
  }

  &__toolbar-icon {
    color: $c-active;
  }

  &__toolbar-text {
    color: $c-grey;
    font-family: $fnt-family-1;
    a {
      color: $c-grey;
      font-family: $fnt-family-1;
      font-weight: 400;
      &:hover {
        color:$c-active;
      }
    }
  }

  &__detail-icon {
    color: $c-active;
  }

  &__detail-target {
    color: $c-grey;
    font-family: $fnt-family-1;
  }

  &__button-icon {
    color: $c-dark;
    transition: 0.2s all;

    &:hover {
      color: $c-active;
      transition: 0.2s all;
    }
  }

  &__button-number {
    background-color: $c-active;
  }

  &__menu {
    @include menu-size-min {
      background-color: $c-active;

      .main-menu {
        > .menu-item {
          > a:hover {
            background-color: fade-out($c-dark, 0.85);
          }

          &:hover {
            > a {
              background-color: fade-out($c-dark, 0.85);
            }
          }
        }

      }
    }
  }

  // Main menu
  .main-menu {
    @include menu-size-max {
      .menu-item {
        a {
          color: $c-dark;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min {
      .menu-item {
        a {
          color: $c-grey;
        }

        &:hover {
          > a,
          .dropdown-btn {
            color: $c-dark;
          }
        }
      }
    }
  }

  .aht-socials {
    &__icon {
      &:hover {
        color: $c-active;
      }
    }
  }

  .hamburger {

    &-inner {
      background-color: $c-dark;

      &:before,
      &:after {
        background-color: $c-dark;
      }
    }
  }
}

.aheto-header-11 {
  &__socials-title {
    color: $c-active;
  }

  // Main menu
  .main-menu {
    .menu-item {
       a {
         color: $c-dark;

         &:before {
           background-color: $c-active !important;
         }

         &:hover {
           color:$c-active;

           > a {
             color:$c-active;
           }
         }
      }
    }

    > .menu-item {
      > a {
        &:before {
          background-color: $c-dark;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {
    .menu-item {
      a {
        &:hover {
          color: $c-active;
        }
      }
    }
  }

  .dropdown-btn {
    transition: all 0.05s;
    border: 1px solid transparent;
    border-radius: 3px;
    line-height: 13px;
    position: relative;
    top: 3px;

    &:hover {
      transition: all 0.05s;
      border: 1px solid transparent;
      color: $c-active;
    }

    @include menu-size-max {
      border:1px solid #ddd;
      color:$c-dark;
    }

  }
  // Mega menu
  .mega-menu {

    &__title {
      color: $c-grey;
    }
  }

  .dropdown-btn {
    font-size: 16px;
  }

  .hamburger {

    &-inner {
      border-top: 1px solid rgba($c-dark, 0.5);
      border-bottom: 1px solid rgba($c-dark, 0.5);
      background-color: $c-dark;

      &:before,
      &:after {
        background-color: $c-dark;
      }
    }
  }

  .aheto-socials {
    &__icon {
      color: $c-light;

      &:hover {
        color: $c-active;
      }
    }
  }
}

.aheto-header-12 {
  // Main menu
  .main-menu {
    @include menu-size-min {

      .menu-item {
        a {
          color: $c-grey;
        }

        &:hover {

          > a,
          > .dropdown-btn {
            color: $c-active;
          }
        }
      }

      > .menu-item {
        > a {
          color: $c-dark;
        }
      }
    }

    @include menu-size-max {
      .menu-item {
        a {
          color: $c-active;
        }
      }

      > .menu-item {
        > a {
          color: $c-dark;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      box-shadow: 0px 0px 5px 0px rgba($c-active, 0.1);
    }
  }

  // Mega menu
  .mega-menu {
    @include menu-size-min {
      &__title {
        color: $c-dark;
      }

      &__col {
        &:before {
          background-color: $c-light;
        }
      }
    }

    @include menu-size-max {
      &__title {
        color: $c-grey;
      }
    }
  }

  // Hamburger
  .hamburger {
    &-inner {
      &:before,
      &:after {
        background-color: $c-dark;
      }
    }

    @include menu-size-min {
      &.is-active {
        .hamburger-inner {
          background-color: $c-dark;
        }
      }
    }

    @include menu-size-max {
      &.is-active {
        .hamburger-inner {
          background-color: $c-dark;
        }
      }
    }
  }

  // Logo
  .logo {

    &__text {
      color: $c-active;
      &::first-letter {
        color: $c-dark;
      }
    }
  }

  // Search form
  form {
    font-family: $fnt-family-1;

    input,
    button {
      color: $c-dark;
    }

    input {
      border-bottom: 1px solid $c-light;
      &:focus {
        border-color: $c-active;
      }
    }

    i {
      &:hover {
        color:$c-active;
      }
    }
  }
  // Dropdown-btn
  .dropdown-btn {
    @include menu-size-max {
      border: 1px solid $c-light;
      color: $c-dark;
    }
  }

  &__aside-overlay {
    @include menu-size-max {
      &.is-open {
        background-color: rgba($c-dark, 0.7);
      }
    }
  }

  &__aside-close-icon {
    color: $c-dark;
  }

  &__cart-link {
    color: $c-dark;

    &:hover {
      color:$c-active;
    }
  }
}

.aheto-header-13 {
  &__inner {
    @include menu-size-max {
      background-color: $c-dark;
    }
  }

  &__button-link {
    &:hover {
      color: $c-active;
    }
  }

  // Menu
  &__menu {
    @include menu-size-max {
      background: linear-gradient(to bottom, $c-dark, rgba($c-dark, 0.85));
    }
  }

  // Main menu
  .main-menu {

    @include menu-size-min {
      > .menu-item {
        
        &.active {
          &:before {
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 4px;
            background-color: $c-active;
          }
        }

        &:before {
          background-color: $c-active;
        }
      }
    }
  }

  // Sub menu
  .sub-menu {

    @include menu-size-min {
      .menu-item {
        a {
          color: $c-dark;
        }

        &:hover {
          > a,
          > .dropdown-btn {
            color: $c-active;
          }
        }
      }

      .dropdown-btn {
        color: $c-dark;
      }
    }
  }

  // Mega menu
  .mega-menu {

    @include menu-size-max {
      &__title {
        color: $c-active;
      }
    }
  }
}

.aheto-header-14 {
  background-color: $c-active;

  &__logo {
    background-color: $c-alter;
  }

  &__button-icon {
    &:hover {
      color:$c-alter;
    }
  }

  // Main menu
  .main-menu {
    .menu-item {
      a {
        font-family: $fnt-family-1;
      }
    }

    @include menu-size-min {
      > .menu-item {
        &:hover {
          > a,
          > .dropdown-btn {
            color: $c-alter;
          }
        }
      }
    }

  }

  // Sub menu
  .sub-menu {
    @include menu-size-min {
      border-top: 3px solid $c-alter;
      .menu-item {
        a {
          color: $c-active;
        }

        &:hover {
          > a,
          > .dropdown-btn {
            color: $c-alter;
          }
        }
      }

      .dropdown-btn {
        color: $c-active;
      }
    }
  }

  // Menu item
  .mega-menu {
    @include menu-size-min {

      &__title {
        color: $c-grey;
      }
    }

    @include menu-size-max {
      &__title {
        color: $c-active;
      }
    }
  }

  // Socials
  .aht-socials {
    &__icon {
      &:hover {
        color: $c-alter;
      }
    }
  }
}

.main-header {
  // Hamburger
  .hamburger {
    &-inner {
      background-color: $c-dark;
    }
  }

  // Icons
  &__icons {
    padding-right: 15px;
    padding-top: 24px;

    .icons-widget__item {
      color:$c-dark;

      &:hover {
        color: $c-active;
      }
    }
  }
  // Dropdown button
  .dropdown-btn {
    color: $c-grey;
  }

  // Main menu
  .main-menu {
    &--ci-rounded-solid-bg {
      @include menu-size-min {
        > .menu-item {
          &:hover {
            > a {
              background-color: rgba($c-active, 0.1);
            }
          }
        }
      }
    }

    &--ddt-default {
      .sub-menu {
        .menu-item {
          > a {
            color: $c-grey;
            font-weight: 400;
            &:hover {
              color: $c-dark;
            }
          }
          > a.active{
            color: $c-dark;
          }
        }
      }
    }

    // Dropdown styles
    @include menu-size-min {

      &--ddt-default {

        .sub-menu {
          box-shadow: 0px 0px 13px 0px rgba($c-active, 0.09);
          .menu-item {
            > a {
              color: $c-grey;

              &:hover {
                color: $c-dark;
              }
            }
            > a.active {
              color: $c-dark;

            }


            &:hover {
              > .dropdown-btn {
                color: $c-dark;
              }
            }
          }
        }

        &.main-menu--ddc-light {
          .sub-menu {
            background-color: $c-light;
          }
        }

        &.main-menu--ddc-dark {
          .sub-menu {
            background-color: $c-dark;
          }
        }
      }

      &--ddt-classic {

        .sub-menu {
          box-shadow: 0px 0px 2px 0px rgba($c-active, 0.2);

          .menu-item {

            > a,
            > .dropdown-btn {
              color: $c-dark;
            }

            &:hover {
              > a,
              > .dropdown-btn {
                color: $c-active;
              }
            }
          }
        }

        .mega-menu {

          &__title {
            color: $c-grey;
          }
        }

        &.main-menu--ddc-light {
          .sub-menu {
            background-color: $c-light;
          }
        }

        &.main-menu--ddc-dark {
          .sub-menu {
            background-color: $c-dark;
          }
        }
      }

      &--ddt-clean {

        .sub-menu {
          box-shadow: 0px 0px 5px 0px rgba($c-active, 0.1);
        }

        &.main-menu--ddc-light {
          .sub-menu {
            background-color: $c-light;
          }
        }

        &.main-menu--ddc-dark {
          .sub-menu {
            background-color: $c-dark;
          }

          .mega-menu {
            &__title {
              color: $c-light;
            }
          }
        }
      }
    }
  }

  // Menu Item
  .menu-item {

    &:before {
      background-color: $c-active;
    }

    a {
      color: $c-dark;
    }

    // Current
    &.current-menu-item {
      > a {
        color: $c-active;
      }
    }

    @include menu-size-min {
      &:hover {

        > a {
          color: $c-active;
        }

        > .dropdown-btn {
          color: $c-active;
        }
      }

      // Current
      &.current-menu-item {
        > .dropdown-btn {
          &:before {
            color: $c-active;
          }
        }
      }
    }

    @include menu-size-max {
      > a {
        &:hover {
          color: $c-active;
        }
      }
    }
  }

  // Mega menu
  .mega-menu {
    &__title {
      color: $c-active;
    }
  }

  &--solid {
    $solid-color: $c-active;
    .main-header__menu-wrap {
      background-color: $solid-color;
    }

    .main-header__menu-box {
      @include menu-size-max {
        background-color: $solid-color;
      }
    }
  }

  &--grad {
    $color-grad-1: lighten($c-active, 40%);
    $color-grad-2: $c-white;

    .main-header__menu-wrap {
      background-image: linear-gradient(to bottom, $color-grad-1 0%, $color-grad-2 100%);
    }
  }

  &--grad-hor {
    $color-grad-1: $c-white;
    $color-grad-2: lighten($c-active, 40%);

    .main-header__menu-wrap {
      background-image: linear-gradient(to right, $color-grad-1 0%, $color-grad-2 100%);
    }

    @include menu-size-max {
      .main-header__menu-box {
        background-image: linear-gradient(to right, $color-grad-1 0%, $color-grad-2 100%);
      }
    }
  }

  &--fullscreen-menu {
    @include menu-size-min {
      .dropdown-btn {
        color: $c-dark !important;

        &:hover {
          color: $c-active !important;
        }
      }
      .menu-item {
        a {
          color: $c-dark !important;
          &:hover {
            color: $c-active !important;
          }
        }
      }

      .mega-menu {
        &__title {
          color: $c-dark !important;
        }
      }

      &.is-open {
        .hamburger {
          &-inner {
            background-color: $c-dark;
          }
        }
      }
    }
  }

  &--fullscreen-menu.main-header--menu-dark {
    @include menu-size-min {
      .dropdown-btn {
        &:hover {
          color: $c-active !important;
        }
      }

      .main-header__menu-box {
        background-color: rgba($c-dark, 0.95);
      }

      .menu-item {
        a {
          &:hover {
            color: $c-active !important;
          }
        }
      }
    }
  }
  // Widgets
  &__widgets-area {
    .aht-socials {
      &__item {
        a {
          border-color: $c-grey;
          color: $c-grey;
        }
      }
    }
  }

  .button-number {
    background-color: $c-active;
  }

  .wpml {
    &-ls-legacy-dropdown {
      &:hover {
        > ul > li > a .wpml-ls-native {
          color: $c-active;
        }
      }
    }

    &-ls-native {
      color: $c-dark;
      &:hover {
          color: $c-active;
      }

      i {
        color: $c-grey;
      }
    }

    &-ls-sub-menu {
      li {
        a:hover {
          color:$c-active;
        }

      }
    }
  }
}

.aheto-header--edu-contact {
  .label {
    font-family: $fnt-family-2;
  }
}
