/**
 * common variebles
 * and style
 */

$link-transition: 0.3s ease-out;

// default link
a {
	transition: $link-transition;
	color: $aheto-link-primary-color;
	font-family: $aheto-link-font-family;
	font-size: 15px;
	font-style: $aheto-link-font-style;
	font-weight: $aheto-link-font-weight;
	letter-spacing: $aheto-link-letter-space;
	text-decoration: none;
	&:hover {
		color:$aheto-link-default-color;
	}
}

// btn default
.aheto-btn {
	@include mix-btn-radius($btn-border-radius, $btn-line-height, $btn-standart-size, $btn-standart-padd-vert, $btn-border-size);
	display: inline-block;
	position: relative;
	padding: $btn-standart-padd-vert $btn-standart-padd-horiz;
	border-width: $btn-border-size;
	border-style: solid;
	border-color: rgba($btn-default-border, $btn-border-opacity);
	outline: none;
	background-color: $btn-default-bg;
	color: $btn-default-color;
	font-family: $btn-fnt-family;
	font-size: $btn-standart-size;
	font-style: normal;
	font-weight: $btn-font-weight;
	letter-spacing: $btn-standart-letter-space;
	line-height: $btn-line-height;
	&:hover {
		color:$btn-default-bg;
	}
	\\
	i,
	span {
		font-size: $btn-ico-size;
		&.aheto-btn__icon--box {
			font-size: $btn-ico-large-size;
		}
	}
	&.aheto-btn--outline {
		border:1px solid fade-out($c-grey, 0.8);
		color: $c-active;
		&:hover {
			border-color: $c-active;
			background: $c-active;
			color: $c-white !important;
		}
	}

	&.aheto-btn--inline{
		color: $btn-default-bg;
	}
	&.aheto-btn--underline {
		color: $btn-default-bg;
		&:after {
			background-color: $btn-default-bg;
		}
		&:hover {
			&:after {
				background-color: rgba($btn-default-bg, 0);
			}
		}
	}

	&.aheto-btn--shadow {
		&:not(.aheto-btn--circle) {
			box-shadow: $btn-shadow-top $btn-shadow-left $btn-shadow-blur $btn-shadow-size rgba($btn-default-bg, $btn-shadow-opacity);
		}
		&.aheto-btn--circle {
			box-shadow: $btn-circle-shadow-top $btn-circle-shadow-left $btn-circle-shadow-blur $btn-circle-shadow-size rgba($btn-default-bg, $btn-circle-shadow-opacity);
		}
	}
	.aheto-btn__icon--box {
		&:after {
			background-color: $btn-alter-bg;
		}
	}

	// btn large
	&--large {
		@include mix-btn-radius($btn-border-radius, $btn-line-height, $btn-large-size, $btn-large-padd-vert, $btn-border-size);
		padding: $btn-large-padd-vert $btn-large-padd-horiz;
		font-size: $btn-large-size;
		letter-spacing: $btn-large-letter-space;
	}

	//btn small
	&--small {
		@include mix-btn-radius($btn-border-radius, $btn-line-height, $btn-small-size, $btn-small-padd-vert, $btn-border-size);
		padding: $btn-small-padd-vert $btn-small-padd-horiz;
		font-size: $btn-small-size;
		letter-spacing: $btn-small-letter-space;
	}

	// btn light
	&--light {
		border:1px solid rgba($btn-light-border, $btn-border-opacity);
		background-color: $btn-light-bg;
		color: $btn-light-color;
		&:hover {
			color:$c-white;
		}
		&.aheto-btn--trans,
		&.aheto-btn--inline,
		&.aheto-btn--underline {
			color: $btn-light-bg;

			&:hover {
				color:$c-white;
			}
		}
		&.aheto-btn--underline {
			&:after {
				background-color: $btn-light-bg;
			}
		}
		&.aheto-btn--shadow {
			&:not(.aheto-btn--circle) {
				box-shadow: $btn-shadow-top $btn-shadow-left $btn-shadow-blur $btn-shadow-size rgba($btn-light-color, $btn-shadow-opacity);
			}
			&.aheto-btn--circle {
				box-shadow: $btn-circle-shadow-top $btn-circle-shadow-left $btn-circle-shadow-blur $btn-circle-shadow-size rgba($btn-light-color, $btn-circle-shadow-opacity);
			}
			&:hover {
				color:$c-white;
				border:1px solid fade-out($c-white, 0.7);
			}
		}
	}

	// btn dark
	&--dark {
		border-color: rgba($btn-dark-border, $btn-border-opacity);
		background-color: $btn-dark-bg;
		color: $btn-dark-color;
		&.aheto-btn--trans,
		&.aheto-btn--inline,
		&.aheto-btn--underline {
			color: $btn-dark-bg;
		}

		&.aheto-btn--underline {
			&:after {
				background-color: $btn-dark-bg;
			}
		}

		&.aheto-btn--shadow {
			&:not(.aheto-btn--circle) {
				box-shadow: $btn-shadow-top $btn-shadow-left $btn-shadow-blur $btn-shadow-size rgba($btn-dark-bg, $btn-shadow-opacity);
			}
			&.aheto-btn--circle {
				box-shadow: $btn-circle-shadow-top $btn-circle-shadow-left $btn-circle-shadow-blur $btn-circle-shadow-size rgba($btn-dark-bg, $btn-circle-shadow-opacity);
			}
		}
	}
	&--grey{
		&.aheto-btn--trans,
		&.aheto-btn--inline,
		&.aheto-btn--underline {
			color: $ol-fnt-color;
		}

	}
	// btn gradient
	&--gradient {
		border: 0;
		background: $btn-gradient-bg;
		&.aheto-btn--circle {
			background: $btn-gradient-circle-bg;
		}
		&.aheto-btn--shadow {
			&.aheto-btn--circle {
				box-shadow: $btn-circle-shadow-top $btn-circle-shadow-left $btn-circle-shadow-blur $btn-circle-shadow-size rgba($btn-alter-bg, $btn-circle-shadow-opacity);
			}
		}
	}

	// btn alternative
	&--alter {
		border-color: rgba($btn-alter-border, $btn-border-opacity);
		background-color: $btn-alter-bg;
		color: $btn-alter-color;
		&:hover{
			color: $btn-alter-bg;
		}
		&.aheto-btn--trans,
		&.aheto-btn--inline,
		&.aheto-btn--underline {
			color: $btn-alter-bg;
		}
		&.aheto-btn--underline {
			&:after {
				background-color: $btn-alter-bg;
			}
		}
		&.aheto-btn--shadow {
			&:not(.aheto-btn--circle) {
				box-shadow: $btn-shadow-top $btn-shadow-left $btn-shadow-blur $btn-shadow-size rgba($btn-alter-bg, $btn-shadow-opacity);
			}
			&.aheto-btn--circle {
				box-shadow: $btn-circle-shadow-top $btn-circle-shadow-left $btn-circle-shadow-blur $btn-circle-shadow-size rgba($btn-alter-bg, $btn-circle-shadow-opacity);
			}
		}
		.aheto-btn__icon--box {
			&:after {
				background-color: $btn-default-bg;
			}
		}
	}

	// btn transparent
	&--trans {
		background-color: $btn-transparent-bg;
		color: $c-active;
	}
	// btn light
	&--no-padd {
		padding: 0px;
	}
	// btn inline
	&--inline {
		font-size: $btn-inline-size;
		font-weight: $btn-inline-weight;
		letter-spacing: $btn-inline-letter-space;
	}

	// btn underline
	&--underline {
		font-size: $btn-inline-size;
		font-weight: $btn-inline-weight;
		letter-spacing: $btn-inline-letter-space;
	}

	// btn big size (rectangle)
	&--rect {
		padding-top: $btn-rect-padd-top;
		padding-right: $btn-rect-padd-right;
		padding-bottom: $btn-rect-padd-bottom;
		padding-left: $btn-rect-padd-left;
		font-size: $btn-rect-font-size;
		letter-spacing: $btn-rect-letter-space;
	}

	// Btn circle
	&--circle {
		width: $btn-circle-width;
		height: $btn-circle-height;
		i {
			font-size: $btn-circle-icon-size;
		}
	}
	// btn none border
	&--simple {
		border: none;
		color: $c-white;
		border: 1px solid transparent;
		&:hover{
			color: $c-white;
			border: 1px solid $c-white;
		}
	}
}

// Icon inside button
.aheto-btn__icon {
	&--left {
		margin-right: $btn-ico-margin;
	}
	&--right {
		margin-left: $btn-ico-margin;
	}
	&--box {
		&:after {
			@include mix-btn-radius($btn-border-radius, $btn-line-height, $btn-standart-size, $btn-standart-padd-vert, $btn-border-size);
		}
	}
	&--default-color {
		color: $btn-default-bg;
	}
}

.aheto-video-link {
	display: flex;
	align-items: center;
	cursor:pointer;
	@media screen and (max-width: 768px) {
		display: inline-flex;
	}
	&__btn {
		height: 70px;
		width: 70px;
		display: flex;
		justify-content: center;
		align-items: center;
		color:  $c-white;
		font-size: 24px;
		position: relative;
		transition: .5s ease-in-out;
		&:before{
			content:'';
			height: 70px;
			width: 70px;
			background: $c-alter;
			border-radius: 50%;
			position: absolute;
		}
		&:hover{
			&:before{
				background: transparent;
				border: 1px solid $c-alter;
			}
			i{
				color: $c-alter;
			}
		}
		i{
			z-index: 1;
		}
		&--big{
			height: 90px;
			width: 90px;
		}
		&--pos{
			justify-content: flex-end;
			@media screen and (max-width: 768px) {
				justify-content: center;
			}
		}
	}
	&--border{
		height: 130px;
		width: 130px;
		justify-content: center;
		&:after {
			content: '';
			height: 130px;
			width: 130px;
			border-radius: 50%;
			border: 1px solid #eeeeee;
			position: absolute;
			transition: .5s ease-in-out;
		}
		&:before {
			content: '';
			height: 90px;
			width: 90px;
			background: $c-alter;
			border-radius: 50%;
			position: absolute;
		}
		span{
			&:hover {
				border: none;
			}
		}
		&:hover {
			&:before {
				background: transparent;
				border: 1px solid $c-white;
			}
			i {
				color: $c-alter;
			}
			span{
				&:before {
					background: transparent;
					border: none;
				}
			}
			&:after {
				content: '';
				height: 90px;
				width: 90px;
				opacity: 0;
			}
		}


	}
	&__label{
		font-size: 14px;
		font-weight: 500;
		color: #222222;
		text-transform: uppercase;
		padding-left: 20px;
		letter-spacing: 1.4px;
	}
}
.aheto-btn--nobg{
	color: #fff;
	&:hover{
		color: #ff704f;
	}
}

.aheto-video-link__btn--chr {
	&:before {
		// background: #fc8e44;
		background: $c-active;
	}
}

.aheto-video-link--border--chr {
	&:before {
		// background: #fc8e44;
		background: $c-active;
	}

	&:after {
		border: 1px solid #eeeeee;
		opacity: 0.5;
	}

	&:hover {
		i {
			// color: #fc8e44;
			color: $c-active;
		}
	}
}

.aheto-member__dark-hover:hover {
	color: $c-dark;
}
