.aheto-footer-10 {
  &__main {
    padding: 105px 0 30px;
    @include medium-size-max {
      padding: 50px 0 0;
    }
  }

  .aheto-socials {
    &__link {
      margin-left: 25px;

      .icon {
        color: rgba($c-white, 0.4);
      }
    }
  }

  .widget_recent_entries_3 {
    li {
      border-color: $c-black;
      padding-bottom: 20px;
      margin-bottom: 20px;
      @include small-size-max{
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }

    a {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.5;
    }
  }
}
