.aht-table {
    width: 100%;
    &__title {
        color: $c-dark;
    }
    &__head {
        &-item {
            color: $c-active;
        }
    }
    &__body {
        &-row:nth-child(odd) {
            // background-color: rgba($c-active, 0.04)
            background-color: rgba(#fff8fb, 0.6);
        }
    }
    &--edu {
        .aht-table {
            &__header {
                color: $c-dark;
                font-family: $fnt-family-2;
            }
            &__head {
                &-item {
                    font-family: $f-roboto;
                    
                }
            }
            &__body {
                &-row:nth-child(odd) {
                    // background-color: rgba($c-active, 0.04)
                    background-color: rgba(#f8f8fb, 0.6);
                }
                &-cell {
                    font-family: $f-roboto;
                }
                &-cell:nth-last-child(-n+3) {
                    color: $c-grey;
                    font-size: 15px;
                }
            }
        }
    }
}