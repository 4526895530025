.aheto-form {
  &--default {
    // Input
    .wpcf7-form-control {
      color: $c-dark;
      &::placeholder {
        color: $c-grey;
      }
      &:focus {
        border: 1px solid $c-active;
        box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);
      }
    }

    // Select
    .wpcf7-form-control.wpcf7-select {
      color: $c-grey;
      background: $c-white;
      &:focus {
        border: 1px solid $c-active;
      }
      &.cf7-selected {
        color: $c-dark;
      }
    }

    // input alert (for example if field is required)
    .wpcf7-not-valid-tip {
      color: $c-active;
    }

    // Submit button
    .wpcf7-form-control.wpcf7-submit {
      border-color: rgba($c-active, 0);
      background-color: $c-active;
      box-shadow: 1.169px 3.825px 15.66px 2.34px rgba($c-active, 0.2);
      &:hover {
        background-color: transparent;
        color: $c-active;
      }
    }

    // Notifications and errors
    .wpcf7-not-valid-tip {
      color: $c-active;
    }

    .wpcf7-response-output {
      color: $c-active;
    }
  }
  &--political{
    // Input
    .wpcf7-form-control {
      &:focus {
        border: 1px solid $c-alter;
        box-shadow: 0px 15px 30px 0px rgba($c-alter, 0.1);
      }
    }
    // Select
    .wpcf7-form-control.wpcf7-select {
      &:focus {
        border: 1px solid $c-alter;
      }
    }
    // Submit button
    .wpcf7-form-control.wpcf7-submit {
      box-shadow: none;
      border: 1px solid $c-active;
      font-weight: 700;
      font-size: 18px;
      padding: 20px 30px
    }
  }
  // Form for Pop up (page Home Event)
  &--pop-up {
    .wpcf7-form {
      // Input
      .wpcf7-form-control {
        color: $c-active;
        &::placeholder {
          color: $c-dark;
        }

        &:focus {
          border-bottom: 1px solid $c-dark;
        }
      }

      .wpcf7-not-valid-tip {
        color: $c-active;
      }

      .wpcf7-form-control.wpcf7-select {
        color: $c-dark;
        &.cf7-selected {
          color: $c-active;
        }
      }

      .wpcf7-form-control.wpcf7-submit {
        border-color: rgba($c-active, 0.5);
        background-color: $c-active;
      }

      .wpcf7-not-valid-tip {
        color: $c-active;
      }

      .wpcf7-response-output {
        color: $c-active;
      }
    }
  }

  &--saas {
    .wpcf7-form {
      .wpcf7-form-control {
        color: $c-dark;

        &.wpcf7-select {
          color: $c-grey;

          &.cf7-selected {
            color: $c-dark;
          }
        }

        &::placeholder {
          color: $c-grey;
        }

        &.wpcf7-submit {
          border-color: $c-alter;
          background: $c-alter;
          color: $c-white;
          box-shadow: 1.169px 3.825px 15.66px 2.34px rgba($c-alter, 0.2);
          &:hover{
            background-color: transparent;
            color: $c-alter;
          }
        }
      }
    }
  }

  &--business {
    .wpcf7-form-control{
      color: $c-dark;
      &:focus{
        border: 1px solid $c-alter;
        box-shadow: 0px 15px 30px 0px rgba($c-alter, 0.1);
      }
      &.wpcf7-submit{
        border-color:$c-alter;
        background: $c-alter;
        box-shadow: 0px 0px 32px 0px rgba($c-alter, 0.3);
        &:hover{
          color: $c-alter;
        }
      }
    }
    .wpcf7-form-control.wpcf7-select {
      border-radius: 0px;
      &:focus{
        border: 1px solid $c-alter;
        box-shadow: 0px 15px 30px 0px rgba($c-alter, 0.1);
      }
      &.selected {
        color: $c-dark;
      }
    }
  }

  &--yoga {
    .wpcf7-form-control {
      color: $c-dark;

      &:focus {
        border-color: $c-active;
      }

      &.wpcf7-select {
        &.selected {
          color: $c-dark;
        }
      }
    }

    .wpcf7-form-control-wrap.Budget {
      position: relative;
      
      &:before {
        display: block;
        content: "";
        position: absolute;
        right: 30px;
        top: 32px;
        width: 6px;
        height: 1px;
        background-color: #676767;
        transform: rotate(40deg);
      }

      &:after {
        display: block;
        content: "";
        position: absolute;
        right: 26px;
        top: 32px;
        width: 6px;
        height: 1px;
        background-color: #676767;
        transform: rotate(-40deg);
      }
    }
    
    .wpcf7-form-control.wpcf7-select {
      -webkit-appearance: none;
    }

    .wpcf7-form-control.wpcf7-textarea {
      border-radius: 10px;
    }
  }

  &--chr {
    .wpcf7-form-control {
      color: $c-grey;

      &::placeholder {
        color: $c-grey;
      }

      &:focus {
        color: $c-dark;
        border: 1px solid $c-active;
        box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);
      }
    }
  }
}

.aheto-footer-5 {
  .mc4wp-form-fields {
    height: 130px;
  }
}

// Email form
.aheto-form-email {


  // Default styles
  .mc4wp-form {
    &-fields {
      input {
        &[type=email] {
          color: $c-dark;

          &::placeholder {
            color: $c-grey;
          }
        }
      }
    }
  }

  // Email form for page Home Event
  &--event {
    .mc4wp-form {
      .mc4wp-form-fields {
        input {
          &[type=email] {
            color: $c-grey;

            &::placeholder {
              color: $c-grey;
            }
          }

          &[type=submit] {
            background-color: $c-active;
          }
        }
      }
    }
  }

  // Email form for page Home Education
  &--education {
    .mc4wp-form {
      .mc4wp-form-fields {
        input {
          &[type=email] {
            color: $c-grey;

            &::placeholder {
              color: $c-grey;
            }
          }

          &[type=submit] {
            background-color: $c-active;
            color: $c-dark;
          }
        }
      }
    }
  }

  &--business {
    .mc4wp-form-fields {
      background-color: $c-alter-3;

      input {
        &[type=submit] {
          color: $c-active;
          @media screen and (max-width: 450px) {
            border: 1px solid;
          }
        }
        &:hover{
          &[type=submit] {
            color: $c-alter;
          }
        }
      }
    }
  }
}

.aht-form {
  &--edu {
    .wpcf7 {
      &-form-control-wrap {
        .wpcf7-form-control {
          color: $c-grey;
          font-family: $f-roboto;
          &::placeholder {
            color: $c-grey;
            font-family: $f-roboto;
          }
  
          &:focus {
            border-color: $c-dark;
            box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
          }
        } 
      }

      &-submit {
        border-color: $c-dark;
        background-color: $c-dark;
        font-family: $f-roboto;
        letter-spacing: 1.4px; 
        &:hover {
          border-color: $c-active;
          background-color: $c-active;
          color: $c-dark;
        }
      }
    }
  }

  &--trvl {
    .wpcf7 {

      &-form-control {
        color: $c-dark;
        &::placeholder {
          color: $c-grey;
        }

        &:focus {
          border-color: $c-active;
          box-shadow: 0px 15px 30px 0px rgba($c-dark, 0.1);
        }
      }

      &-select {
        color: $c-grey;

        &.selected {
          color: $c-dark;
        }
      }

      &-select {
        option {
          color: $c-dark;
        }
      }

      &-submit {
        background-color: $c-active;
        &:hover {
          background-color: $c-alter;
        }
      }
    }
  }
}


// church sermons-det page
.church-sermons-det-wrap {
  .aheto-form {
    &--default {
      .wpcf7-form-control.wpcf7-submit {
        box-shadow: 0 0 32px 0 rgba($c-active, 0.3);
      }
    }
  }
}