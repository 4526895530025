.aheto-video {
  &__play {
    background: $c-active;

    &:before {
      border: 4px solid $c-active;
    }
  }

  &__pause {
    background: $c-active;
  }
}

.aht-video {

  &--trvl {
    .aht-video {
      &__link {
        color: $c-dark;
        &:hover {
          color: $c-active;
        }
      }
    }
  }
}
