.aht-banner {
  b {
    color: $c-active;
  }

  &__overlay {
    &--2 {
      background: linear-gradient(36deg, $c-black 0%, $c-alter 100%);
    }

    &--3 {
      background: linear-gradient(to right, transparent 10%, darken($c-dark, 30%));
    }
  }


  &--with-promo {
    .aht-banner__promo {
      background: linear-gradient(24deg, $c-alter, $c-active);
    }
  }

  &--travel-1 {
    .aht-banner {
     &__link {
        &:not(:hover) {
          color: $c-active;
        }
      }
    }
  }

  &--travel-2 {
    .aht-banner {
      &__sub-title {
        font-family: $fnt-family-1;
      }
      &__link {
        border-color: $c-active;
        background-color: $c-active;
		
        &:hover {
          color: $c-dark;
        }		
      }
    }
  }

  &--travel-3 {
    .aht-banner {
      &__sub-title {
        font-family: $fnt-family-1;
      }	
      &__link {
        border-color: $c-active;
        background-color: $c-active;

        &:hover {
          color: $c-dark;
        }		
      }
    }
  }

  &--yoga {
    .aht-banner {
      &__link {
        border-color: $c-active;
        background-color: $c-active;

        &:hover {
          color: $c-dark;
        }
      }
    }
  }

  &--yoga-2 {
    .aht-banner {
      &__link {
        &:nth-child(odd) {
          border-color: $c-active;
          background-color: $c-active;
        }

        &:hover {
          border-color: $c-dark;
          background-color: $c-dark;
          color: $c-white;
        }
      }
    }
  }

  &--yoga-3 {
    .aht-banner {

      &__title {
        color: $c-active;
      }

      &__link {
        border-color: $c-dark;
        background-color: $c-dark;

        &:nth-child(even) {
          border-color: $c-dark;
          background-color: $c-dark;
        }

        &:hover {
          color: $c-white;
          border-color: $c-active;
          background-color: $c-active;
        }
      }
    }
  }

  &--construction {
    .aht-banner {
      &__title {
        span {
          color: $c-active;
        }
      }
      &__links {
        a {
          &:nth-child(odd) {
            border-color: $c-active;
            background-color: $c-active;
          }
		  
		  &:nth-child(even) {
            color: $c-dark;
          }		  
        }
      }
    }
  }

  &--edu {

    &--1 {
      .aht-banner {
	  
        &__sub-title {
          font-family: $f-roboto;
        }
        &__link {
          font-family: $f-roboto;
          font-size: 14px;
          &:not(:hover) {
            border-color: $c-active;
            background-color: $c-active;
            color: $c-dark;
          }
        }
      }
    }

    &--2 {
      .aht-banner {
        &__link {
          font-family: $f-roboto;
          font-size: 14px;
        }
        &__link {
          color: $c-dark;
        }
      }
    }
	
    &--3 {
      .aht-banner {

        &__link {
          &:nth-child(odd) {
            color: $c-dark;
          }

          &:hover {
            border-color: $c-dark;
            background-color: $c-dark;
          }
		  
          &:nth-child(even) {
            border-color: $c-active;
            background-color: $c-active;
            color: $c-dark;
          }
        }
      }
    }
  }
}

.aht-ban {
  &--main {
    .aht-ban {
      &__video {
        color: $c-active;
		
        &:hover {
          color: $c-dark;
        }		
      }
    }

    &-1 {      
      .aht-ban {
        &__link-prime {
          &:hover {
            color: $c-active;
          }
        }

        &__link-second {
          &:hover {
            color: $c-active;
            &:after {
              background-color: $c-active;
            }
          }
        }
      }
    }
  }

  &--chr {
    .aht-ban {
      &__title {
        font-family: $fnt-family-2;
	  }
	  
      &__link {
        &:nth-child(odd) {
          &:hover {
            color: $c-dark;
          }
        }
		
        &:nth-child(even) {
          &:hover {
            color: $c-active;
            &:before {
              background-color: $c-active;
            }
          }
        }
      }
    }
  }
}

.swiper--trvl {
  .aht-banner__promo {
    box-sizing: border-box;
    background-image: linear-gradient(to left, #fa6742, #ff961a);
  }

  .switzerland-banner {
    .aht-banner__link {
      color: #222;

      &:hover {
        background-color: $c-active;
        color: $c-white;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    &:hover {
      &:before {
        color: #ddd;
      }
    }
  }
}
