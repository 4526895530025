.aht-td {
  &__tb {
    &-star {
      color: $c-alter;
    }
  }

  &__spec {
    &-icon {
      color: $c-alter;
    }

    &-text {
      color: $c-dark;
    }
  }

  &__menu {
    &:before {
      background-color: $c-light;
    }

    &-text {
      color: $c-dark;
      &:before {
        background-color: $c-active;
      }

      &:hover,
      &.active {
        color: $c-active;
      }
    }
  }

  &__detail {
    &-check {
      color: $c-active;
    }

    &-close {
      color: $c-grey;
    }
  }

  &__route {
    &-caption {
      &.active {
        .aht-td__route-title {
          color: $c-active;
        }
      }
    }

    &-icon {
      color: $c-grey;
    }

  }

  &__book {
    &-caption {
      &--discount {
        &:before {
          background-color: $c-active;
        }
      }
    }
    
    &-cost {
      background-image: linear-gradient(24deg, $c-alter, $c-active, $c-active);
    }

    &-discount {
      font-family: $fnt-family-1;
    }
  }

  &__btn {
    flex: 1;
    color: $c-dark;
    font-family: $fnt-family-2;
    &.active,
    &:hover {
      color: $c-active;
    }
  }

  &__element {
    color: $c-dark;
    font-family: $fnt-family-2;

    &:focus {
      border: 1px solid $c-active;
      box-shadow: 0px 10px 20px 0px rgba($c-dark, 0.07);
    }
  }

  &__submit {
    font-family: $fnt-family-1;
    &:hover {
      border-color: $c-active;
      background-color: $c-active;
    }
  }

  &__why {
    &-icon {
      color: $c-alter;
    }
  }
}
