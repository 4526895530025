body .swiper {
  &-button-prev {
    color: rgba($c-active, 0.2);
    &:hover {
      color:$c-active;
    }
  }

  &-button-next {
    color: rgba($c-active, 0.2);
    &:hover {
      color:$c-active;
    }
  }

  &-pagination {
    &-bullet {
       &-active {
        background-color: $c-active;
      }
    }

    &-progressbar {
      &-fill {
        background-color: $c-active;
      }
   }
  }
  &--events-posts {
    .swiper {
      &-pagination {
          &-bullet-active {
            background-color: $c-grey;
          }
      }
    }
  }

  &--home-event {

    .swiper {
      &-button-prev,
      &-button-next {
        border: 1px solid rgba($c-dark, 0.1);
        i {
          &:before {
            color: $c-dark;
          }
        }

        &:hover {
          background-color: $c-dark;
        }
      }
    }
  }

  &--shop-item {
    .swiper-button-prev,
    .swiper-button-next {
      color: $c-grey;
    }

    .gallery-thumbs {
      .swiper-slide {
        &-active {
          > div {
            border: 1px solid $c-dark;
          }
        }
      }
    }
  }

  &--banner-hp {
    .swiper {

      &-button-prev,
      &-button-next {
        &:hover {
          i {
            color: $c-dark;
          }
        }
      }
    }
  }

  &--edu {
    .swiper {

      &-button-prev,
      &-button-next {
        color: $c-dark;
        i {
          color: $c-dark;
        }
        &:hover {
          &:hover {
            background-color: $c-dark;
            color: $c-active;
          }
        }
      }
      &-button-prev,
      &-button-next {
        &:hover {
          &::before {
            color: $c-light;
          }

        }
      }
      &-pagination {
        &-bullet-active {
          background-color: $c-dark!important;
        }
      }
    }
  }

  &--edu-tm {
    .swiper {
      &-pagination {
        &-bullet-active {
          background-color: $c-dark!important;
        }
      }
    }
  }

  &--edu-courses {
    .swiper {

      &-slide {
        > div {
          height: 100%;
        }
      }

      &-button-prev,
      &-button-next {
        color: $c-dark;
        font-size: 16px;

        &:hover {
          background-color: $c-dark;
          color: $c-light;
        }
      }
    }
  }
  &--edu-posts {

    .swiper {
      &-pagination {
        &-bullet {
          margin: 0 5px;
          &-active {
            background-color: $c-dark;
          }
        }
      }
    }
  }
  &--edu-class-table {
    background-color: $c-white;
  }
  
  &--edu-events {
    .swiper {
      &-pagination {
        &-progressbar {
          &-fill {
            background-color: $c-dark;
          }
        }
      }
      &-button-next, &-button-prev {
        color: rgba($c-grey, 0.2);
        &:hover {
          color: $c-dark;
        }
      }
    }
  }

  &--edu-images {
    .swiper {
      &-button-prev,
      &-button-next {
        i {
          color: $c-dark;
        }

        &:hover {
          background-color: $c-dark;
        }
      }
    }
  }

  &--edu-blog {
    .swiper {
      &-button-prev,
      &-button-next {
        &::before {
          color: $c-white;
        }
      }
    }
  }

  &--websites {
    .swiper {
      &-slide {
        img {
          box-shadow: 0px 10px 30px 0px rgba($c-active, 0.1);
        }
      }
    }
  }

  &--saas {
    width: 100%;
    .swiper-button-prev,
    .swiper-button-next {
      color: #cccccc;
      top: calc(50% - 43px);
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  &--testimonials {
    .swiper-pagination-bullet-active {
      background: rgba($c-grey, 0.4);
    }
  }

  &--trvl {

    .swiper {
      &-button-next,
      &-button-prev {
        color: $c-dark;
        &:hover {
          color: $c-grey;
        }
      }
    }
  }

  &--trvl-3 {
    .swiper {
      &-button-prev,
      &-button-next {
        color: $c-dark;
        &:hover {
          background-color: $c-active;
        }
      }
    }
  }

  &--business {
    .swiper-container{
      padding: 30px;
    }
    .swiper {
      &-pagination {
        &-bullet {
          border: 1px solid $c-alter;
          &-active {
            background-color: $c-alter;
          }
        }
      }
    }
  }

  &--vert-pag {
    .swiper {
      &-pagination {
        &-bullet {
          border: 1px solid $c-grey;
          &-active {
            background-color: $c-grey;
          }
        }
      }
    }
  }

  &--yoga-2 {
    .swiper {

      &-button-prev:before {
        background-image: url('../img/yoga/home-yoga/ion-ios-arrow-thin-left-ionicons.png');
      }

      &-button-next:before {
        background-image: url('../img/yoga/home-yoga/ion-ios-arrow-thin-right-ionicons.png');
      }

      &-button-prev,
      &-button-next {
        background-color: #f7f7f7;
        border: 1px solid #f7f7f7;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
          background: none;
        }

        &:before {
          content: "";
          width: 16px;
          height: 10px;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        i {
          color: $c-dark;
        }
      }
    }
  }

  &--constrution-service {
    .swiper {
      &-button-prev,
      &-button-next {
        border: 1px solid #d9d9d9;
        color: $c-dark-2;
        &:hover {
          background-color: #eeeeee;
          border: 1px solid #eeeeee;
        }
      }
    }
  }

  &--twitter {
    .swiper {
      &-button-prev,
      &-button-next {
        color: $c-grey;
        &:hover {
          box-shadow: 0px 5px 10px 0px rgba($c-dark, 0.1);
        }
      }
    }
  }

  &--chr-2 {
    .swiper {
      &-pagination {
        &-bullet {
          // border: 1px solid $c-dark-2;
          border: 1px solid #c2c2c2;

          &-active {
            // background-color: $c-dark-2;
            background-color: #929292;
          }
        }
      }
    }
  }

  &--constrution{
    .swiper-button{
      &-prev,
      &-next{
        color: $c-white;
        &:hover{
          color: #ebb113;
          opacity: 1;
        }
      }
    }
  }

  &--chr-3 {
    .swiper {
      &-pagination {
        &-bullet {
          border: 1px solid $c-dark-2;
          &-active {
            background-color: $c-dark-2;
          }
        }
      }

      &-button-prev,
      &-button-next {
        &:hover {
          color: $c-active;
        }
      }
    }
  }
}

.swiper.home-banner{
  .swiper-button-prev {
    &:hover {
      color: $c-dark;
    }
  }
  .swiper-button-next {
    &:hover {
      color: $c-dark;
    }
  }
}
