.aheto-titlebar {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
  b {
    color: $c-active;
  }
  }

  &__overlay {
  &--grad {
    background: linear-gradient(36deg, $c-black 0%, $c-dark 100%);
  }
  }

  &__input {
  form {
    input {
    &[type=text] {
      font-family: $fnt-family-1;

      &::placeholder {
      font-family: $fnt-family-1;
      }
    }

    &[type=submit] {
      background-color: $c-active;
      font-family: $fnt-family-1;
    }
    }
  }
  }

  &__breadcrumbs {
  li {
    &.current {
    &:before {
      color: $c-grey;
    }
    }
  }
  }

  .c-active {
  background-color: $c-active;
  }
  .c-grey {
  background-color: $c-grey;
  }
  .c-light {
  background-color: $c-light;
  }
  .c-dark {
  background-color: $c-dark;
  }
  .c-dark-2 {
  background-color: $c-dark-2;
  }
  .c-alter {
  background-color: $c-alter;
  }
  .c-alter-2 {
  background-color: $c-alter-2;
  }
  .c-alter-3 {
  background-color: $c-alter-3;
  }

  // Intro block
  &__intro {
  background-color: $c-active;
  }

  &__breadcrumbs {
    li {
      &:last-child {
        color: $c-white;
      }
    }
  }
  &__breadcrumbs2 {
    li {
      &:last-child {
        color: $c-grey;
      }
    }
  }

  &--yoga-details,
  &--yoga-contact {
    .aheto-titlebar__breadcrumbs {
      margin-bottom: 25px;
    }

    .aheto-titlebar__content {
      margin-top: 15px;
    }
  }

  &--yoga-contact {
    @include small-size-max {
      .aheto-titlebar__breadcrumbs {
        margin-bottom: 135px;
      }

      .aheto-titlebar__content {
        margin-top: 70px;
      }
    }
  }
}
