.aheto-counter {
  // Classic
  &--classic {
    .aheto-counter {
      &__number {
      color: $c-active;
      }
    }
  }

  // Modern
  &--modern {

    .aheto-counter {
      &__icon {
      color: $c-active;
      }

      &__number {
      color: $c-dark;
      }

	}
  }

  &--divider {
    &::after {
      background-color: rgba($c-dark, 0.1);
    }
  }

  &--yoga {
    .aheto-counter {
      &__number {
        font-family: 'Gilroy', sans-serif;
        color: $c-active;
      }

      &__desc {
        font-weight: 400;
      }
    }

    &.aheto-counter--active {
      .aheto-counter__number {
        position: relative;

        &:before {
          color: $c-active;
        }

        &:after {
          position: absolute;
          left: 100px;
          top: -15px;
          display: block;
          content: "+";
          font-size: 30px;
          font-family: 'Gilroy', sans-serif;
        }
      }
    }
  }

  &--edu-1 {
    .aheto-counter {

      &__number {
        color: $c-active;
      }
    }
  }

  &--edu-2 {
    .aheto-counter {

      &__number {
        color: $c-active;
      }
      &__title {
        font-family: $f-roboto;
        letter-spacing: 1.6px;
      }
    }
  }

  &--simple {
    background-color: $c-active;
  }
}
