.aht-text {
  &--trvl {
    .aht-text {
      &__text {
        font-family: $fnt-family-1;
      }
    }
  }

  &--classic {
    .aht-text {
      &__text {
        font-family: $fnt-family-1;
      }
    }
  }
}

.list {
  li {
      color: $c-grey;
  }
}
