.aht-blog-yoga {
  &__img {
    &:after {
      background-color: rgba($c-dark, 0.8);
    }
  }
  &__cat {
    background-color: $c-alter-2;
    height: 22px;
    line-height: 24px;
    padding: 0 18px;  

    &--c-1 {
      background-color: $c-active;
    }

    &--c-2 {
      background-color: $c-alter;
    }

    &--c-4 {
      background-color: $c-alter-3;
    }

    &--c-5 {
      background-color: $c-dark;
    }

    &--c-6 {
      background-color: $c-grey;
    }
  }
  &__link {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    @include menu-size-min {
      &:hover {
        border-color: $c-active;
        background: none;
        color: $c-active;
      }
    }
  }
  &__det-icon {
    color: $c-active;
  }

  &__det-text {
    color: $c-dark;
  }
}

.aht-post-yoga {
  &__detail-icon {
    color: $c-active;
  }

  &__author-link {
    font-size: 16px;
  }

  &__detail-text {
    color: $c-dark;
  }

  &__cats-name {
    color: $c-dark;
  }

  &__cats-list {
    color: $c-active;
  }

  &__likes {
    color: $c-dark;
    &:active {
      border-color: $c-active;
    }

    &:hover {
      box-shadow: 0 0 20px 0 rgba($c-active, 0.13);
    }
  }
}

.aht-sidebar-yoga {

  .widget_categories {
    a {
      color: $c-grey;
      &:hover {
        border-color: $c-active;
        background-color: $c-active;
      }
    }
  }

  .search {

    &-field {
      color: $c-dark;
      &::placeholder {
        color: $c-grey;
      }
      &:focus {
        border: 1px solid $c-active;
        box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);
      }
    }
  }
  
  .aht-widget-advert {
    &__link {
      &:hover {
        border-color: $c-active;
        background-color: $c-active;
      }
    }
  }

  .aht-widget-posts {
    &__button {
      color: $c-dark;
      &.active {
        border-bottom: 2px solid $c-active;
        color: $c-active;
      }
    }
    &__title {
      color: $c-dark;
    }
  }
  
}
