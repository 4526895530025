.aheto-member {
  $k: &;

  &__link {
    &:hover {
      color:$c-active;
    }
  }
  &__icon {
    &:hover {
      color:$c-active;
    }
  }
  &--home-page {
    .aheto-member__contact {
      box-shadow: 0px 11px 30px 0px rgba($c-active, 0.1);
    }
  }
  &--modern {
    .aheto-member__link {
      &:hover {
        color: $c-active;
      }
    }
  }

  &--home-event {
    .aheto-member__img-holder {
      &:after {
        background-color: rgba($c-dark, 0.7);
      }
    }
    @include small-size-max {
	  .aheto-member__icon-plus {
        color: $c-dark;
      }
    }
  }

  &--home-education {
    .aheto-member__position {
      color: $c-active;
      font-family: $f-roboto;
      letter-spacing: 1.5px;
    }
    .aheto-member__icon {
      color: $c-dark;
      &:hover {
        color: $c-active;
      }
      // @media screen and (max-width: 575px) {
      //   &-plus {

      //     &::before {
      //       color: $c-white;
      //     }
      //   }
      // }
    }

    .aheto-member__link {
      color: $c-dark;
      &:hover {
        color: $c-active;
      }
    }
    .aheto-member__desc {
      font-family: $f-roboto;
    }

    .aheto-member__link-plus {
      color: $c-grey;

      &:hover {
        border: 1px solid $c-dark;
        background-color: $c-dark;
      }
    }

    // @media screen and (max-width: 575px ) {
    //   .aheto-member__link-plus {
    //     border-color: $c-dark;
    //     background-color: $c-dark;
    //   }
    // }
  }

  &--saas {
    .aheto-member {
      &__contact {
        &:after,
        &:before {
          background: rgba($c-dark, .6);
        }
      }
      &__link {
        &:hover {
          color: $c-active;
        }
      }
    }
  }

  &--barbershop {
    .aheto-member {
      &__name {
        color: $c-active;
      }
    }
  }

  &--dark {
    #{$k}__name {
      color: $c-active;
    }
  }

  &--border {
    &:hover {
        box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);
      }

      &::after {
        background-color: $c-active;
      }
  }

  &--border-2 {

    .aheto-member__link {
      &:hover {
        color: $c-active;
      }
    }

    @include small-size-min {
      &:hover {
        box-shadow: 0px 15px 30px 0px rgba($c-alter, 0.1);
      }

      &::after {
        background-color: $c-active;
      }
    }
  }

  &--business {
    border: 1px solid $c-alter-3;

    @include large-size-min {
      &:hover {
        box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);

        .aheto-member__img {
          box-shadow: 0px 15px 30px 0px rgba($c-active, 0.1);
        }
      }
    }

    .aheto-member__position {
      color: $c-active;
    }

    .aheto-member__link-plus {
      border: 1px solid lighten($c-grey, 14%);
      &:hover {
        border-color: $c-active;
        background-color: $c-active;
      }
    }

    .aheto-member__icon-plus {
      color: lighten($c-grey, 14%);
    }

    .aheto-member__link {
      color: lighten($c-grey, 14%);

      &:hover {
        color: $c-active;
      }
    }
  }

  &--yoga {
    .aheto-member {
      &__position {
        background-color: $c-active;
        &.c-2 {
          background-color: $c-alter;
        }

        &.c-3 {
          background-color: $c-alter-2;
        }

        &.c-4 {
          background-color: $c-alter-3;
        }
      }

      &__desc {
        font-weight: 400;
      }
    }

    .aheto-btn--dark-2 {
      .aheto-member__icon:hover {
        color: #222;
      }
    }

    .aheto-btn--trans {
      i {
       color: #ccc;

       &:hover {
        color: #707070;
       }
      }
    }
  }
}

.aht-team {
  &--t1 {
    &:hover {
      box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.3);
    }

    .aheto-socials__link {
      &:hover {
        i {
          color: $c-alter;
        }
      }
    }
  }

  &--edu {
    .aht-team {
      &__pos {
        color: $c-active;
        font-family: $f-roboto;
      }
      &__desc {
        font-family: $f-roboto;
      }
    }
    &:hover {
      .aht-team {
        &__caption {
          background-color: $c-dark;
        }

        &__img {
          &:before {
            background-color: rgba($c-black, 0.2);
          }
        }
        &__desc {
          color: rgba($c-white, 0.6);
        }
        &__socials {
          .aheto-socials {
            &__link {
              .icon {
                color: $c-white;
                &:hover {
                  color: $c-active;
                }
              }
            }
          }
        }
      }
    }
    
  }

  &--t1 {
    &:before {
      background-color: $c-active;
    }
  }
}

.aht-team {
  &-more {
    background-color: $c-white;
    .aht-team {
      &__header {
        
        &-number {
          color: $c-active;
        }
        &-plus {
          color: $c-active;
        }
        &-text {
          color: $c-grey;
        }
      }
      &__details {
        &-link {
          color: $c-dark;
          &:hover {
            color: $c-active;
          }
        }
      }
       
    }
      
  }
}


.about-church-team-wrap-1,
.church-ministries-wrap,
.church-events-wrap {
  .aheto-member{
    &--home-page {

      .aheto-member__name,
      .aheto-member__position {
        font-family: $f-roboto;
      }

      .aheto-member__contact {
        box-shadow: 0px 15px 30px 0 rgba(42, 116, 237, 0.1);
      }
    }
  }
}