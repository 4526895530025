.aht-blog-trvl {

  &__title {
    color: $c-dark;
    font-family: $f-mukta;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
  }

  &__text {
    font-family: $f-open-sans;
  }
  
  &__icon {
    color: $c-alter;
  }

  &__text {
    color: $c-grey;
  }
}

.aht-post-trvl {

  &__cat {
    background-color: $c-active;
  }

  &__content {

    blockquote {
      p {
        color: $c-dark;
      }
    }
  }

  &__tags {
    &-name {
      color: $c-dark;
    }

    &-list {
      color: $c-active;
    }

    &-link {
      &:hover {
        color: $c-alter;
      }
    }
  }

  &__likes {
    color: $c-dark;
    &:hover {
      box-shadow: 0 0 20px 0 rgba($c-dark, 0.13);
    }
  }

  &__author-link {
    &:hover {
      color: $c-alter;
    }
  }
}
