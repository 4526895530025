.aheto-contact {
  $k: &;

  &--home-event {
    .aheto-contact__info {
      color: $c-dark;
    }

    .aheto-contact__icon {
      color: $c-active;
    }

    .aheto-contact__link {
      color: $c-dark;

      &:hover {
        text-shadow: 0px 0px 0.5px $c-dark;
      }
    }

    &__content {
      background-color: $c-white;

      .aheto-pop-up-btn {
        color: $c-white !important;

        &:hover {
          color: $c-active !important;
        }
      }
    }
  }

  // Simple modificator
  &--simple {
    .aheto-contact {
      &__icon {
        color: $c-active;
      }

      &__info {
        color: $c-grey;
      }

      &__link {
        &:hover {
          color: $c-active;
        }
      }
    }
  }

  // Modern and Sidebar modificator (they are the same)
  &--modern,
  &--sidebar {
    .aheto-contact__link {
      color: $c-dark;

      &:hover {
        color: $c-active;
      }
    }
  }

  // Multi location modificator
  &--multi-loc {
    .aheto-contact__icon {
      color: $c-active;
    }

    .aheto-contact__link {
      color: $c-grey;
    }
  }

  // Author Bio modificator
  &--author-bio {
    .aheto-contact__mail {
      color: $c-active;
    }

    .aheto-contact__tel {
      color: $c-grey;
    }
  }

  &--saas {
    #{$k}__info {
      color: $c-grey;
    }
  }

  &--business {
    .aheto-contact__title {
      color: $c-alter;

      &:hover {
        color: $c-active;
      }
    }

    .aheto-contact__link-map {
      color: $c-active;

      &.aheto-btn--underline {
        &:hover {
          color: $c-alter;
        }
      }
    }

    .aheto-contact__icon {
      color: darken($c-alter-3, 13%);
    }

    .aheto-contact__info {
      color: $c-dark;
    }

    .aheto-contact__link {
      color: $c-dark;

      &--email {
        color: $c-alter;
      }
    }
  }
}

.aht-contact {

  &--edu {
    .aht-contact {
      &__subtitle {
        color: $c-active;
        font-family: $f-roboto;
        letter-spacing: 1.4px;
      }

      &__link {
        font-family: $f-roboto;
        letter-spacing: 1.4px;

        &:hover {
          color: $c-active;
        }
      }

      &__email,
      &__tel {
        font-family: $fnt-family-2;
        letter-spacing: normal;
      }
    }
  }

  &--yoga {
    .aht-contact {

      &__icon {
        margin-bottom: 13px;
      }

      &__icon-inner {
        color: $c-active;
      }

      &__touch-inner {
        color: $c-grey;
        font-weight: 400;
      }
    }
  }

  &--trvl {
    .aht-contact {

      &__icon {
        color: $c-alter;
      }

      &__text {
        color: $c-grey;

        &--link {
          &:hover {
            color: $c-active;
          }
        }
      }

      &__link,
      &__marker {
        &:hover {
          color: $c-alter;

          &:after {
            background-color: $c-alter;
          }
        }
      }
    }
  }
}

.aht-socials__link {
  &:hover {
    color: $c-active;
  }
}

// Church contact-us page
.church-contact-us-wrap {
  .aht-heading {
   &__title {
    color: #333333;
   }
  }
}

.aheto-form--yoga {
  .wpcf7-form-control.wpcf7-submit {
    margin-top: 34px;
    box-shadow: none;

    &:hover {
      border-color: $c-active;
    }
  }
}