.aheto-pricing {
  $p: &;
  &--alternative {
    .aheto-pricing__header {
      background: rgba($c-active, 0.04);
    }

    .aheto-pricing__cost {
      color: $c-active;
    }

    .aheto-pricing__btn {
      border-color: fade-out($c-grey, 0.8);
      color: $c-active;
    }

    @include medium-size-min {
      &:hover {
        background-color: $c-active;

        .aheto-pricing__content {
          border-color: $c-active;
        }

        .aheto-pricing__btn {
          background-color: $btn-light-bg;
          color: $btn-light-color;
        }

        .aheto-pricing__header {
          background-color: darken($c-active, 6%);
        }
      }
    }
  }

  .aheto-pricing__description {
    ul {
      li {
        color:$c-grey;

        &:before {
            background-color:$c-active;
        }
      }
    }
  }

  &--home-saas {
    #{$p}__title {
      color: $c-active;
    }
  }

  &--legend {
    #{$p}__title {
      color: $c-dark;
      letter-spacing: 0.6px;
      justify-content: start;
      padding-left: 70px;
    }
  }

  &__ico {
    color: $c-active;
  }

  &__footer {
    .aheto-btn {
      &:hover {
        background-color: $btn-default-bg;
        color: $btn-default-color;
      }
    }
  }

  &__options {
    &-item {
      background: $c-active;
    }
  }
}

// krm table item
.aheto-pricing {
  &--tableHead {
    .aheto-pricing__header {
      background: $c-active;
    }

    .aheto-pricing__list-item {
        &:before {
          background: $c-active;
        }
    }

    .aheto-pricing__list {
      &-item {
        h6 {
          &:before {
            color: $c-active;
          }
        }
      }
    }
  }

  &--tableColumn {
    @include medium-size-min {
      &:hover {
        .aheto-pricing__header {
          background-color: darken($c-active, 6%);
        }
      }
    }

    .aheto-pricing__btn {
      &:hover {
        background-color: $btn-default-bg;
        color: $btn-default-color;
      }
    }

    .aheto-pricing__header {
      background-color: $c-active;
    }
  }

}

.aheto-pricing {
  &--home-event {
    background-color: transparent;

    .aheto-pricing__header {
      border-bottom: 1px dotted $c-active;
    }

    .aheto-pricing__cost-value {
      color: $c-active;
    }

    .aheto-pricing__detail {
      &::after {
        border-bottom: 1px solid rgba($c-active, 0.2);
      }
    }

    .aheto-pricing__btn {
      color: $c-white!important;
      &:hover {
        border-color: $c-active;
        background-color: $c-active;
        color: $c-white!important;
      }
    }
  }

  &--home-education {
    border: 1px solid darken($c-active, 2%);
    background-color: $c-active;

    @media screen and (max-width: 991px) {
      margin: 0;
      &:hover {
        background-color: $c-active;
      }
    }

    .aheto-pricing__description {
      color: $c-active;
    }

    .aheto-pricing__price-period {
      color: $c-active;
    }

    .aheto-pricing__btn {
      &:hover {
        border-color: $c-active;
        background-color: $c-active;
        color: $c-active;
      }
    }

  }
  &--home-construction {
    .aheto-pricing {
       &:hover{
         .aheto-pricing__footer {
           .aheto-btn{
               color: $c-white;
               background: $c-active ;
           }
         }
       }
      &__title{
        color: $c-dark-2;
      }
      &__cost-value {
        color: $c-active;
        font-family: $fnt-family-1;
      }
      &__description {
        li {
          &:after {
            background-color: $c-active;
          }
        }
      }
      &__footer {
        .aheto-btn{
          &:hover{
            color: $c-white;
            background: $c-active ;
          }
        }
      }
    }

  }
}

.aht-pricing {
  &--business {

    .aht-pricing {

      &__head {
        &-content {
          border-right: 1px solid darken($c-light, 3%);
        }

        &-caption {
          background-color: darken($c-light, 3%);
        }
      }
    }

    .aht-pricing__item {
      @include medium-size-min {
        &:hover {
          .aht-pricing__item-caption {
            background-color: $c-alter;
          }
        }
      }
      &-caption {
        background-color: lighten($c-alter, 16%);
      }

      &-link {
        border-color: darken($c-light, 2%);
        background-color: darken($c-light, 2%);
        color: $c-grey;

        @include medium-size-max {
          border-color: $c-active;
          background-color: $c-active;
          color: $c-white;
        }

        &:hover {
          border-color: $c-active;
          background-color: $c-active;
          box-shadow: 0px 0px 32px 0px rgba($c-active, 0.3);
        }
      }
    }
  }

  &--yoga {
    .aht-pricing {

      &__item {
        box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.1);

        .aht-pricing__item-item {
          position: relative;

          &:before {
            left: -5px;
            position: absolute;
            color: $c-active;
          }
        }

        &--active {
          &:before {
            background-color: $c-alter;
          }
        }
      }

      &__item-price {
        color: $c-active;
      }

      &__item-desc {
        font-family: $fnt-family-2;
      }

	  &__item-item {
        color: $c-grey;
      }

      &__item-link {
        color: $c-dark-2;

        &:hover {
          background-color: $c-dark;
        }
      }
    }
  }

  &--edu {
    .aht-pricing {

      &__item {
        background-color: $c-light;
        &:hover {
          box-shadow: 0px 0px 46.98px 7.02px rgba($c-dark-2, 0.05);
        }
      }

      &__icon {
        color: $c-dark;
      }

      &__desc {
        color: $c-active;
        font-family: $f-roboto;
        font-size: 12px;
      }

      &__price {
        color: $c-dark;
        font-family: $f-roboto-slab;
      }

      &__per {
        color: $c-dark;
        font-family: $f-karla;
      }

      &__link {
        font-family: $fnt-family-1;
        &:hover {
          border-color: $c-active;
          background-color: $c-active;
          color: $c-dark;
        }
      }
    }
  }

  &--trvl {
    .aht-pricing {

      &__inner {
        background-color: $c-light;
      }

      &__desc {
        color: $c-grey;
        font-weight: 500;
      }

      &__price {
        color: $c-active;
      }
      &__link {
        &:hover {
          border-color: $c-active;
          background-color: $c-active;
        }
      }

      &__content {
        padding-top: 23px;
      }

      &__list-item {
        padding: 15px 0;
        letter-spacing: 0.3px;
        color: #999;
      }
    }
  }
}

.corporate {
  color:$c-grey;
}
