.widget {
  &_aheto {
    &__info {
      color: $c-dark;
    }
    &__link {
      color: $c-dark;
    }
    &__icon {
      color: $c-active;
    }
    &__link {
      color: $c-grey;
    }
    .aheto-socials {
      &__link {
        color: darken($c-grey, 19%);
        &__icon {
          color: darken($c-grey, 19%);
        }
      }
    }
  }
  // NavMenu
  &_nav_menu {
    .menu {
      &-item {
        a {
          color: $c-grey;
          font-size: 16px;
        }
      }
    }
    &_1 {
      .menu {
        &-item {
          a {
            color: $c-grey;
            &:before {
              border-color: transparent $c-grey;
            }
          }
          &:hover {
            a {
              color: $c-active;
              &:hover {
                &:before {
                  border-color: transparent $c-active;
                }
              }
            }
          }
          &.current-menu-item {
            a {
              color: $c-active;
              &:before {
                border-color: transparent $c-active;
              }
            }
          }
        }
      }
    }
    &_2 {
      .menu {
        &-item {
          a {
            color: $c-grey;
          }
        }
      }
    }
    &_3 {
      .menu {
        &-item {
          a {
            color: $c-grey;
            &:before {
              background-color: $c-active;
            }
          }
          &:hover {
            a {
              color: $c-active;
              &:hover {
                &:before {
                  border-color: transparent $c-active;
                }
              }
            }
          }
          &.current-menu-item {
            a {
              &:before {
                background-color: $c-active;
              }
            }
          }
        }
      }
    }
  }
  // Recent entries
  &_recent_entries {
    &_1 {
      .post-date {
        color: $c-grey;
      }
    }
    &_2 {

      a {
        color: $c-dark;
        &:hover {
          color: $c-active;
        }
      }
      .post-date {
        color: $c-grey;
        font-family: $f-roboto;
      }
    }
    &_3 {
      li {
        border-bottom: 1px solid rgba($c-grey, 0.3);
      }
      a {
        &:hover {
          color: $c-active;
        }
      }
      .post-date {
        color: $c-grey;
      }
    }
  }
  // Mailchimp Newsletter
  &_mc4wp_form_widget {

    &.aheto_mc {
      &_1 {
        input {
          &[type=submit] {
            background-color: $c-active;
            &:hover {
              background: fade-out($c-active, 0.2) !important;
            }
          }
        }
      }
      &_2 {
        button,
        input {
          color: $c-dark;
          &::placeholder {
            color: $c-grey;
          }
          &[type=submit] {
            background: $c-alter;
            &:hover {
              background: fade-out($c-active, 0.2) !important;
            }
          }
        }
      }
      &_3 {
        input {
          color: $c-dark;
          &::placeholder {
            color: $c-grey;
          }
        }
        button {
          &[type=submit] {
            color: $c-active;
            &:hover {
              background-color: $c-active;
            }
          }
        }
      }
    }
  }
  // Time Schedule
  &_time_schedule {
    li {
      color: $c-grey;
    }
  }
  // Form
  &_custom_form {
    textarea,
    input[type=text],
    input[type=email],
    input[type=url] {
      font-family: $fnt-family-3;
    }
    button,
    input {
      &[type=submit] {
        background-color: $c-active;
        font-family: $fnt-family-3;
        &:hover {
          background: fade-out($c-active, 0.2) !important;
        }
      }
    }
  }
  // Twitter
  &_twitter {
    .widget-add {
      span,
      a {
        font-family: $fnt-family-3;
      }
    }
  }
  &-courses {
    &--edu {
      .widget {
        &-title {
          font-family: $f-roboto;
        }
      }
      .aht-courses-wd {
        &__link {
          color: $c-grey;
          font-family: $f-roboto;
          &:hover {
            color: $c-dark;
          }
        }
      }
    }
  }

}
// Social
.aheto-socials {
  &__link {
    .icon {
      color: $c-grey;
    }
    &:hover{
      .icon{
        color: $c-active;
      }
    }
  }
  .aht-socials {
    &__link {
      color: $c-dark;
      &:hover {
        color: $c-active;
      }
    }
  }
}

// Footer menu
.aheto-footer-menu {
  .menu {
    &-item {
      a {
        font-family: $fnt-family-1;
        &:hover {
          color: $c-active;
        }
      }
    }
  }
}

.aht-socials--circle .aht-socials__link {
    border: 1px solid $c-grey;
    color:$c-grey;
}

.aheto-footer-1 {
  .widget {
    &-title {
      border-bottom: 1px solid rgba($c-grey, 0.25);
    }
  }
}

.aheto-footer-2 {
  .widget {
    &_aheto {
      &__info {
        color: $c-grey;
        a {
          color: $c-grey;
        }
      }
    }
    &-title {
      color: $c-active;
    }
  }
}

.aheto-footer-4 {
  .widget {
    &_aheto {
      &__link {
        color: $c-dark;
        &:hover {
          color: $c-active;
        }
      }
    }
    &-title {
      color: $c-dark;
    }
  }
}

.aheto-footer-5 {
  .widget {
    &_aheto {
      &__info {
        color: $c-grey;
      }
      &__link {
        color: $c-grey;
      }
    }
    &_mc4wp_form_widget {
      &.aheto_mc {
        &_2 {
          button,
          input {
            &[type=submit] {
              background: $c-active;
              &:hover {
                background: fade-out($c-active, 0.2) !important;
              }
            }
          }
        }
      }
    }
  }
}

.aheto-footer-6 {
  .widget {
    &_aheto {
      &__link {
        &:hover {
          color: $c-active;
        }
      }
    }
    &_recent_entries {
      &_2 {
        li {
          a {
            &:hover {
              color: $c-active;
            }
          }
        }
      }
    }
    &_mc4wp_form_widget {
      &.aheto_mc {
        &_1 {
          button,
          input {
            &[type=email] {
              color: $c-grey;
            }
            &[type=submit] {
              background-color: $c-active;
              color: $c-dark;
              &:hover {
                background: fade-out($c-active, 0.2) !important;
              }
            }
          }
        }
      }
    }
  }
}

.aheto-footer-7 {
  .widget {
    &_nav_menu {
      .menu {
        &-item {
          a {
            color: $c-grey;
          }
        }
      }
    }
  }
}

.aheto-footer-8 {
  .widget {
    // Twitter
    &_twitter {
      .widget-add {
        span,
        a {
          font-family: $fnt-family-1;
        }
      }
    }
  }
}

.aheto-footer-9 {
  .widget {
    &_aheto {
      &__info {
        color: $c-grey;
        a {
          color: $c-grey;
          font-size: 16px;
          &:hover {
            color: $c-active;
          }
        }
        &--tel {
          a {
            color: $c-active;
            font-size: 20px;
            &:hover {
              border-bottom:1px solid $c-active;
            }
          }
        }
      }
    }
    &-title {
      color: $c-dark;
    }
    // Nav menu
    &_nav_menu {
      .menu-item {
        a {
          &:hover {
            color: $c-dark;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.aheto-footer-10 {
  .widget {
    &_aheto {
      &__info {
        color: $c-grey;
        a {
          color: $c-grey;
        }
      }
    }

    &-title {
      color: $c-active;
    }
    &_mc4wp_form_widget {
      &.aheto_mc {
        &_2 {
          button,
          input {
            &[type=submit] {
              background: $c-active;
              &:hover {
                background: fade-out($c-active, 0.2) !important;
              }
            }
          }
        }
      }
    }
  }
  .widget_nav_menu_3{
    .menu-item {
      &:hover{
        a{
          color: $c-white;
        }
      }
    }
  }
}

.aheto-footer-11 {
  .widget {
    &_aheto {
      margin-bottom: 45px;
      &__link {
        color: $c-grey;
        &:hover {
          color: $c-alter;
        }
      }
      &__desc {
        a {
          &:hover {
            color: $c-alter;
          }
        }
      }
    }
    // Nav Manu
    &_nav_menu {
      .menu {
        &-item {
          a {
            color: $c-grey;
            &:hover {
              color: $c-alter;
            }
          }
        }
      }
    }
  }
}

.aheto-footer-12 {
  .widget {
    &-title {
      color: $c-dark;
    }
    &_aheto {
      &__info {
        color: $c-grey;
        a {
          color: $c-grey;
          &:hover {
            color: $c-active;
          }
        }
      }
    }
    .aht-socials {
      &__icon {
        color: $c-active;
        &:hover {
          i {
            color: $c-active;
          }
        }
      }
      &__link {
        &:hover {
          &__icon {
            color: $c-active;
          }
        }
      }
    }
  }
}

.aheto-footer-13 {
  .widget {
    &_aheto {
      &__info {
        color: $c-grey;
        a {
          color: $c-grey;
        }
      }
    }
  }
}

.aheto-footer-14 {
  .widget {
    &_aheto {
      &__info {
        color: $c-grey;
        a {
          color: $c-grey;
        }
      }
    }
  }
}

.aheto-footer-15 {
  .widget {
    &_aheto {
      &__link {
        &:hover {
          color: $c-active;
        }
      }
      &__info {
        color: $c-grey;
        font-weight: 400;
      }
    }
    &_recent_entries {
      &_2 {
        li {
          a {
            &:hover {
              color: $c-active;
            }
          }
        }
      }
    }
    &_mc4wp_form_widget {
      &.aheto_mc {
        &_1 {
          button,
          input {
            &[type=email] {
              color: $c-grey;
            }
            &[type=submit] {
              background-color: $c-active;
              &:hover {
                background: fade-out($c-active, 0.2) !important;
              }
            }
          }
        }
      }
    }
  }
}
.aht-course--edu-details {
  .aht-widget-advert {
    &--edu {
      padding-top: 31px;
      padding-bottom: 36px;
      .aht-widget-advert {
        &__subtitle {
          font-family: $f-roboto;
          color: $c-white;
        }
        &__title {
          font-family: $f-roboto;
        }
        &__desc {
          font-family: $f-roboto;
        }
        &__link {
          font-family: $f-roboto;
        }
      }
    }
  }

  .aht-twitter {
    box-shadow: 0px 15px 30px 0 rgba(42, 116, 237, 0.1);
    background-color: $c-white;
    margin-bottom: 60px;
    &__link {
      color: $c-white;
      background-color: $c-dark;
    }
    .tweets {
      font-family: $f-roboto;
    }
    .btn {
      border-top: 1px solid #eeeeee;
      display: flex;
      &-left, &-right {
        padding: 15px 0px;
        width: 50%;
        cursor: pointer;
      }
      &-left {
        border-right: 1px solid #eeeeee;
      }
    }
    &__follow {
      color: $c-dark;
      font-family: $f-roboto;
    }
  }

  .aheto_mc_edu {
    background: $c-dark url(../img/inner-pages/blog/icon-envelope.png) no-repeat bottom right;
    .mc4wp-form .mc4wp-form-223 {
      .widget-title {
        font-family: $fnt-family-2;
        color: $c-white;
      }
    }
    .mc4wp-form-fields {
      p {
        font-family: $f-roboto;
      }
      input[type="submit"] {
        color: $c-dark;
        background-color: $c-white;
        border: 1px solid rgba($c-white, 0.5);
        font-family: $f-roboto;
        &:hover {
          background: transparent;
          color: $c-white;
          border:1px solid $c-white;
          transition: 0.3s all;
        }
      }
    }
  }

}
