.aheto-socials {
  &__icon {
    &:hover {
      color: $c-active;
    }
  }

  // Circle social
  &--circle {

    .aht-socials__link {
      border: 1px solid #eeeeee;
      color: $c-grey;

      &:hover {
        border-color: $c-active;
        background-color: $c-active;
      }
    }

    .active {
      border-color: $c-active;
      background-color: $c-active;
    }
  }

  &--i-color {
    .aht-socials__link {
      color: $c-grey;
    }
  }

  &--events {
    .aht-socials__link {
      color: $c-white!important;
      &:hover {
        color: $c-active!important;
      }
    }
  }
}

.aheto-socials {
  &__item {
    a {
      &:hover {
        border-color: $c-active;
        background-color: $c-active;
      }
    }
  }
}

.aht-socials {

  &__link {
    color: $c-grey;
  }
  .aht-btn--dark{
    color: $c-black;
  }
  &--circle {
    .aht-socials {

      &__link {
        border: 1px solid rgba($c-grey, 0.2);
        &:hover {
          background-color: $c-active;
        }
      }
    }
  }

  &--edu {
    .aheto-socials {
      &__link {
        .icon {
          color: $c-dark;
        }
        
      }
    }
  }
}


.aheto-footer-5 {
  @include xs-size-all {
    .aht-socials {
      text-align: center;
    }
  }
}

.aht-socials--yoga-details {
  &.aht-socials--circle {
    margin-top: 0;
    margin-bottom: 0;
  }

  .aheto-socials__link {
    height: 50px;
    width: 50px;
    border: 1px solid #eee;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 0;
    display: inline-block;
    float: left;
    position: relative;
    padding-top: 12px;

    i {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $c-active;
      border-color: $c-active;

      i {
        color: #fff;
      }
    }
  }
}
