.aht-cta {

  &__title {
    b {
      color: $c-active;
    }
  }

  &__tel {
    &-icon {
      background-color: $c-active;

      &:after {
        color: $c-active;
      }
    }
    &:hover{
      color: $c-active!important;
      i {
        background-color: $c-white;
        &:after {
          color: $c-white;
        }
      }
    }
  }

  &--simple {
    .aht-cta {
      &__link {
        &:not(:hover) {
          color: $c-dark;
        }
      }
    }
  }

  &--classic {

    .aht-cta {
      &__link {
        border:1px solid $c-active;

        &:hover {
          color: $c-active;
          border:1px solid $c-active;
        }
      }
    }
  }

  &--modern {
    .aht-cta {
    	&__link {
        color: $c-active;
        &.aheto-btn {
          &--trans {
            background-color: $c-active;
            border:1px solid fade-out($c-white, 0.7);

            &:hover {
              color: $c-active;
            }
          }
        }
        &:hover {
          background-color: $c-active;
          border:1px solid fade-out($c-white, 0.7);
        }
      }
    }
  }

  &--t-white {
    .aht-cta__sub-title,
    .aht-cta__title,
    .aht-cta__desc,
    .aht-cta__tel {
      color: $c-white;
    }
  }

  &--yoga {
    &.motto {
      .aheto-btn {
        background-color: $c-active;
        border: 1px solid $c-active;
        color: $c-white;

        &:hover {
          color: $c-active;
          background-color: transparent;
          border-color: $c-active;
        }
      }
    }

    .aht-cta {
      &__sub-title {
        color: $c-active;
      }

      &__link {
        &:hover {
          border-color: $c-white;
          color: $c-white;
          background: none;
        }
      }
    }
  }

  &--construction {

    .aht-cta {
      &__title {
        color: $c-white;
        a {
          color: $c-active;
          &:before {
            background-color: $c-active;
          }
        }
      }
    }
  }

  &--trvl-2 {
    .aht-cta {

      &__subtitle {
        color: $c-active;
        font-family: $fnt-family-3;
      }

      &__form {
        [type=email] {
          color: $c-dark;
          font-family: $fnt-family-2;

          &::placeholder {
            color: $c-grey;
          }
        }

        [type=submit] {
          background-color: $c-active;
          font-family: $fnt-family-1;
          &:hover {
            background-color: $c-alter;
          }
        }
      }
    }
  }

  &--trvl {
    background-color: $c-active;
  }

  &--business{
    .aht-cta__sub-title{
      position: relative;
      display: inline-block;
      &:after{
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 100%;
        height: 2px;
        content: '';
        background: #EEEEEE;
      }
    }
  }
  &--chr {
    .aht-cta {

      &__title {
        font-family: $fnt-family-2;
      }

      &__link {
        &:hover {
          color: $c-active;
        }
      }
    }
  }

  &.custom-yoga-btn {
    .aht-cta__links {
      .aht-cta__link {
        &:last-child {
          padding: 15px 33px;
        }

        &:first-child {
          padding: 16px 41px !important;
        }
      }
    }
  }
}